@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes rainbow {
  0% {color: red;}
  14% {color: orange;}
  28% {color: yellow;}
  42% {color: green;}
  57% {color: blue;}
  71% {color: indigo;}
  85% {color: violet;}
  100% {color: red;}
}

.rainbow-text {
  animation: rainbow 5s linear infinite;
}

.slider::-webkit-slider-thumb {
  @apply bg-blue-700 h-4 w-4 rounded-full appearance-none cursor-pointer;
}

